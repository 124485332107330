svg {
  height: 30px;
}

path {
  fill: white;
}

.bg-vl {
  background-color: #10092e;
}

h1,
h2,
p {
  text-align: center;
}

.dodo {
  display: flex;
  justify-content: center;
  margin: 10px;
  align-items: center;
}

.dodo input {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 75%;
}

Button {
  margin: 10px;
}

ol>li {
  text-align: center;
  padding: 10px;
}