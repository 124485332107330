.list-group {
    width: 300px;
    /* max-height: 400px; */
    /* overflow: scroll; */
    display: flex;
    align-items: center;
    margin: auto;
}

.list-group-item {
    display: flex;
    width: 100%;
    position: initial;
}

.headings-lists {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 70%;
}

#heading-type {
    width: 26%;
}

#paa-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
}

#summary-section {
    width: 100%;
}

.badge {
    height: min-content;
    margin-right: 5px;
}

.inside-new {
    display: flex;
    align-items: center;
}

#remove-content {
    height: min-content;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px
}

.all-content-output {
    display: flex;
    justify-content: center;
}

ol {
    /* list-style: none; */
    list-style-position: inside;
}

ul {
    list-style: none;
    padding: 0px;
    width: 100%;
}

.user-selected-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 30%;

}

#search-bar-content {
    width: 30%;
    border-radius: 0px;
}

h3 {
    text-align: center;
    margin: 10px;
}

.badge-h1 {
    background-color: #845EC2 !important;
  }
  
  .badge-h2 {
    background-color: #D65DB1 !important;
    margin-left: 5px;
  }
  
.badge-h3 {
    background-color: #FF9671 !important;
    margin-left: 10px;
  }
  
  .badge-h4 {
    background-color: #FF6F91 !important;
    margin-left: 15px;
  }
  
  .badge-h5 {
    background-color: #FFC75F !important;
    margin-left: 20px;
  }
  
  .badge-h6 {
    background-color: #c6c648 !important;
    margin-left: 25px;
  }
  
  #link-to-page {
    width: 100%;
    word-wrap: break-word;
  }

  #gpt-prompt-textarea {
    margin-bottom: 25px;
  }

  .link-no-overflow {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .cursor-link {
    cursor: pointer;
  }

  .cursor-draggable {
    cursor: grab;
  }

  .checkbox-design {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
  }

  .form-check-input {
    margin-right: 8px;
  }

  .btn-vl {
    background-color: #6fd0dd;
    border-width: 0px;
    border-radius: 0px;
  }