.avatar {
    border: 2px solid #357294;
    border-radius: 50%;
  }
  
  .header {
    align-items: center;
    background-color: #10092e;
    box-shadow: 0 1px 8px #2e2e2e;
    display: grid;
    gap: 1rem;
    grid-template-columns: 50px auto max-content;
    padding: 1rem;
    position: sticky;
    top: 0;
  }
  
  .header h1 {
    color: white;
    margin: 0;
    text-align: left;
  }
  
  @media screen and (max-width: 600px) {
    .header h1 {
      font-size: 1.5em !important;
    }
  }
  
  .logo {
    position: relative;
    top: -7px;
  }
  
  .user-container {
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: max-content max-content;
  }
  
  .user-name-email {
    color: white;
    font-size: smaller;
  }
  
  @media screen and (max-width: 600px) {
    .user-container {
      grid-template-columns: max-content;
    }
  
    .user-name-email {
      display: none;
    }
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #10092e;
    color: #fff;
    z-index: 1;
    min-width: 100px;
    right: 0px;
    border: 2px solid #357294;
    border-radius: 10px;
  }
  .dropdown-menu-text {
    margin: 1rem 0px;
    text-align: center;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
    margin-right: auto;
    
  }
  .dropdown-content:hover {
    background-color: #fff;
    color: #10092e;
    cursor: pointer;
  }
  
  